import React from 'react'
import Helmet from 'react-helmet'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'
import PasswordForgetForm from '../components/PasswordForget'
import Box from '../components/Box'
import Header from '../components/Header'

const BackgroundBox = styled(Box)`
  background-color: #4844a3;
  min-height: calc(100vh);
`

const PasswordForgetPage = ({ intl }) => (
  <BackgroundBox>
    <Helmet title={intl.formatMessage({ id: 'globals.forgotPassword' })} />
    <Header
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'globals.courseOverview' }),
          path: intl.formatMessage({ id: 'routes.LANDING' }),
        },
        {
          title: intl.formatMessage({ id: 'globals.signIn' }),
          path: intl.formatMessage({ id: 'routes.PASSWORD_FORGET' }),
        },
      ]}
    />
    <PasswordForgetForm intl={intl} />
  </BackgroundBox>
)

export default injectIntl(PasswordForgetPage)
